import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from '@material-ui/core/Button';
import { Accordion } from 'react-bootstrap-accordion'
import 'react-bootstrap-accordion/dist/index.css'
import React, { useState, useProps, useEffect, useMemo } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Typography } from "@material-ui/core";
import ReactMarkdown from "react-markdown"
import remarkGfm from "remark-gfm"
import rehypeRaw from "rehype-raw";

import {Prism as SyntaxHighlighter} from "react-syntax-highlighter"
import {dracula} from "react-syntax-highlighter/dist/esm/styles/prism"

import "./ChatMessageHistory.css";

const styles = {
  optionButton: {
    borderStyle: 'solid', 
    borderWidth: 'thin', 
    borderColor: 'lightgray', 
    overflow: 'hidden', 
    textTransform: 'lowercase', 
    backgroundColor: 'white'
  },
  selectedOptionButton: {
    borderStyle: 'solid', 
    borderWidth: 'thin', 
    borderColor: 'lightgray', 
    overflow: 'hidden', 
    textTransform: 'lowercase', 
    backgroundColor: '#D4D3D3'
  },
  buttonContent: { display: 'flex', flexDirection: 'column' },
  buttonTitle: {
    padding: '8px', 
    borderRadius: '100px', 
    color: 'white', 
    backgroundColor: '#ea5941', 
    fontSize: '12px', 
    position: 'absolute'
  },
  buttonDescription: {
    margin: '20px', 
    marginTop: '50px', 
    alignSelf: 'center', 
    padding: '8px', 
    width: '12vw', 
    overflowWrap: 'break-word', 
    height: '5vh'
  },
  buttonGroup: { display: 'flex', gap: '15px', margin: '10px' },
  chatmodalEngineButton: {
    padding: '5px 10px',
    borderRadius: '20px',
    backgroundColor: '#ff4729',
    color: 'white'
  },
  selectedEngineButton: {
    padding: '5px 10px',
    borderRadius: '20px',
    backgroundColor: '#e33e1f',
    color: 'white'
  },
  chatCustomDialogContent: {
    padding: '0px',
},
}

const ChatMessageHistory = ({ messageHistory, isLoading, currentTab=0, setMessageHistory, messageTips, setMessageTips, setCurrentMessage, showWelcome }) => {

  const [selectedEngine, setSelectedEngine] = useState('tip1');

  const handleSelect = (tip) => {
    messageTips.selected = tip
    const selectedTip = messageTips[tip]
    messageHistory.pop()
    setMessageHistory([...messageHistory, { role: "bot", content: selectedTip, displayOptions: true }])
    setMessageTips(messageTips)
    setSelectedEngine(tip)
  }

  const messageLastIndex = useMemo(() => {
      return messageHistory.length - 1;
  }, [messageHistory]);

  function moveToEndOfMessageHistory() {
    const messageHistoryContainer = document.getElementById("chatmodal-messages-container");
    if (!!messageHistoryContainer) {
      messageHistoryContainer.scrollTop = messageHistoryContainer.scrollHeight - messageHistoryContainer.clientHeight;
    }
  }

  function LinkRenderer(props) {
    return (
      <a href={props.href} target="_blank" rel="noreferrer">
        {props.children}
      </a>
    );
  }

  return (
      <DialogContent className={styles.chatCustomDialogContent}>
          <DialogContentText
            className="chatmodal-messages-container"
            id="chatmodal-messages-container"
          >
          {messageHistory.map((message, index) => (
            <div key={index} className="chatmodal-message">
              { message.displayOptions ? <div style={{marginBottom: '10px'}}>
                  <Accordion defaultActiveKey="0" title="Ver Otras Versiones">
                    <div style={styles.buttonGroup}>
                      <Button 
                        style={messageTips.selected === 'lessons' ? 
                        styles.selectedOptionButton : styles.optionButton
                        }
                        onClick={() => handleSelect('lessons')}>
                        <div style={styles.buttonContent}>
                          <div style={styles.buttonTitle}>
                            Lessons Learned
                          </div>
                          <div style={styles.buttonDescription}>
                            {messageTips.lessons ? <span>
                              {messageTips.lessons}
                            </span> : <CircularProgress />}
                          </div>
                        </div>
                      </Button>
                      <Button 
                        style={messageTips.selected === 'tip1' ? 
                        styles.selectedOptionButton : styles.optionButton
                        }
                        onClick={() => handleSelect('tip1')}>
                        <div style={styles.buttonContent}>
                          <div style={styles.buttonTitle}>
                            Tip 1
                          </div>
                          <div style={styles.buttonDescription}>
                            {messageTips.tip1 ? <span>
                              {messageTips.tip1}
                            </span> : <CircularProgress />}
                          </div>
                        </div>
                      </Button>
                      <Button 
                      style={messageTips.selected === 'tip2' ? 
                      styles.selectedOptionButton : styles.optionButton
                      }
                      onClick={() => handleSelect('tip2')}>
                        <div style={styles.buttonContent}>
                          <div style={styles.buttonTitle}>
                            Tip 2
                          </div>
                          <div style={styles.buttonDescription}>
                            {messageTips.tip2 ? <span>
                              {messageTips.tip2}
                            </span> : <CircularProgress />}
                          </div>
                        </div>
                      </Button>
                      </div>
                    <div style={styles.buttonGroup}>
                      <Button 
                      style={messageTips.selected === 'tip3' ? 
                      styles.selectedOptionButton : styles.optionButton
                      }
                      onClick={() => handleSelect('tip3')}>
                        <div style={styles.buttonContent}>
                          <div style={styles.buttonTitle}>
                            Tip 3
                          </div>
                          <div style={styles.buttonDescription}>
                            {messageTips.tip3 ? <span>
                              {messageTips.tip3}
                            </span> : <CircularProgress />}
                          </div>
                        </div>
                      </Button>
                      <Button 
                      style={messageTips.selected === 'tip4' ? 
                      styles.selectedOptionButton : styles.optionButton
                      }
                      onClick={() => handleSelect('tip4')}>
                        <div style={styles.buttonContent}>
                          <div style={styles.buttonTitle}>
                            Tip 4
                          </div>
                          <div style={styles.buttonDescription}>
                            {messageTips.tip4 ? <span>
                              {messageTips.tip4}
                            </span> : <CircularProgress />}
                          </div>
                        </div>
                      </Button>
                    </div>
                  </Accordion>
                <div className="chatmodal-llm-select-buttons">
                  <Button 
                    style={selectedEngine === 'tip1' ? 
                      styles.selectedEngineButton : styles.chatmodalEngineButton}
                    onClick={() => handleSelect('tip1')}
                  >
                    Tip 1
                  </Button>
                  <Button 
                    style={selectedEngine === 'tip2' ? 
                      styles.selectedEngineButton : styles.chatmodalEngineButton}
                    onClick={() => handleSelect('tip2')}
                  >
                    Tip 2
                  </Button>
                </div>
                </div> : <></>
              }
                <div className="chatmodal-message-role">{ message.role.toUpperCase() } { (index > 0 && index == messageLastIndex) ? '(NEW)' : '' }</div>
                {!message.content ? <div className="chatmodal-message-loading">
                    <CircularProgress />
                  </div> : <ReactMarkdown
                  className="chatmodal-response"
                  children={message.content}
                  rehypePlugins={[rehypeRaw]}
                  remarkPlugins={[remarkGfm]}
                  components={{
                    a: LinkRenderer,
                    code({node, inline, className, children, ...props}) {
                      const match = /language-(\w+)/.exec(className || "")
                      return !inline && match ? (
                        <SyntaxHighlighter
                          {...props}
                          children={String(children).replace(/\n$/, "")}
                          style={dracula}
                          language={match[1]}
                          PreTag="div"
                        />
                        
                      ) : (
                        <code {...props} className={className}>
                          {children}
                        </code>
                      )
                    }
                  }}
                />}
                { isLoading && index === messageHistory.length - 1 && (
                  <div className="chatmodal-message-loading">
                    <CircularProgress />
                  </div>
                )}
              </div>
            ))}
            </DialogContentText>
        </DialogContent>
  );
};

export default ChatMessageHistory;