// in ActionProvider.jsx
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import IconButton from '@material-ui/core/IconButton';
import DialogTitle from "@material-ui/core/DialogTitle";
import React, { useState, useMemo, useEffect } from "react";
import language from '../../constants/language';
import ChatMessageHistory from "./ChatMessageHistory/ChatMessageHistory";
import ChatEngineMessages from "./ChatEngineMessages/ChatEngineMessages";
import ImageUpload from "./ImageUpload";
import { generateUuid } from "./api/messages";
import chatbotIcon from '../../assets/images/isotipo-normal.png';

import useStyles from "./ChatModalStyles";

import { connect } from "react-redux";

import { toggleChatbotModal } from "../../actions/modals/chatbot";

import "./ChatModal.css";

const LEARNED_LESSON_CREATE_URL = "/api/lessons/create";
const DOCUMENTS_ENGINE_URL = "/api/chat/documents";
const OPENAI_ENGINE_URL = "/api/chat/openai";
const ENHANCED_ENGINE_URL = "/api/chat/enhanced";
const STACKOVERFLOW_ENGINE_URL = "/api/chat/stackoverflow";
const LlAMA2_ENGINE_URL = "/api/chat/llama2";

const ChatModal = ({ user, auth, isChatbotModalShown, toggleChatbotModal, selectedLanguage, defaultMessage, useImage, modalType }) => {

  const styles = useStyles();

  const availableEngines = useImage ? [
    { id: 0, label: "Tip Image", endpointUrl: '', name: 'image' },
  ] : [
    { id: 0, label: "Lessons Learned", endpointUrl: DOCUMENTS_ENGINE_URL, name: 'lessons' },
    { id: 1, label: "Tips 1", endpointUrl: OPENAI_ENGINE_URL, name: 'tip1' },
    { id: 2, label: "Tips 2", endpointUrl: ENHANCED_ENGINE_URL, name: 'tip2' },
    { id: 3, label: "Tips 3", endpointUrl: LlAMA2_ENGINE_URL, name: 'tip3' },
    { id: 4, label: "Tips 4", endpointUrl: STACKOVERFLOW_ENGINE_URL, name: 'tip4' },
  ];
  const [mainThreadHistory, setMainThreadHistory] = useState([{ role: "bot", content: language[selectedLanguage].bot.chat.firstMessage, displayOptions: false }]);
  const tabValue = 0;
  const [objectFile, setObjectFile] = useState('');
  const [imageToSend, setImageToSend] = useState('');
  const [messageTips, setMessageTips] = useState({
    lessons: '-',
    tip1: '-',
    tip2: '-',
    tip3: '-',
    tip4: '-',
    selected: 'tip1'
  });

  const[showWelcome, setShowWelcome] = useState(true);

  const [lastQuestion, setLastQuestion] = useState("");
  const [currentMessage, setCurrentMessage] = useState("");
  const [messageToSend, setMessageToSend] = useState("");
  const [feedbackSent, setFeedbackSent] = useState(false);

  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleCollapse = () => {
      setIsCollapsed(!isCollapsed);
  };

  useEffect(() => {
    if (!!defaultMessage && modalType === 'standard'){
      handleNewUserMessage(defaultMessage);
    }
  }, [defaultMessage]);

  const handleClose = () => {
    setMessageToSend('')
    toggleChatbotModal(false, '', '')
  }

  const [state, setState] = useState(
    Object.values(availableEngines).map((engine) => {
      return {
        lastMessage: {},
        newMessageSeen: true,
        isLoading: false
      };
    })
  )

  function handleStateChange(index, newState) {
    setState((prevState) => {
      const newStateArray = [...prevState];
      newStateArray[index] = { ...newStateArray[index], ...newState}
      return newStateArray;
    });
  };

  const childComponents = Object.values(availableEngines).map((engine) => {
    return <ChatEngineMessages
              key={engine.id}
              engine={engine}
              message={messageToSend}
              image={imageToSend}
              useImage={useImage}
              setObjectFile={setObjectFile}
              messageTips={messageTips}
              setMessageTips={setMessageTips}
              setMainThreadHistory={setMainThreadHistory}
              mainThreadHistory={mainThreadHistory}
              isShown={tabValue === engine.id + 1}
              onStateChange={(newState) => handleStateChange(engine.id, newState)}
              {...state[engine.id]}
            ></ChatEngineMessages>
  });


  const anyIsLoading = useMemo(() => {
    const tips = messageTips
    const present = tips.lessons && tips.tip1 && tips.tip2 && tips.tip3 && tips.tip4
    return present ? false : true
  }, [state]);

  async function addMessageToLearnedLessons() {
    const body = JSON.stringify({
      user: 'user',
      question: lastQuestion,
      answer: state[tabValue - 1].lastMessage.content,
      source: availableEngines[tabValue - 1].label,
  });

    const response = await fetch(LEARNED_LESSON_CREATE_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: body,
    });

    setFeedbackSent(true);
  }

  async function handleNewUserMessage(defaultMessageToSend='') {
    setImageToSend(objectFile)
    setMainThreadHistory((prevMainThreadHistory) => {
      const lastMessage = prevMainThreadHistory.pop()
      lastMessage.displayOptions = false
      return [...prevMainThreadHistory, lastMessage]
    });
    setMessageTips({
      lessons: '',
      tip1: '',
      tip2: '',
      tip3: '',
      tip4: '',
      selected: 'tip1'
    });
    setFeedbackSent(false);
    const newMessage = currentMessage || defaultMessageToSend;
    const message = {
      role: 'user',
      content: newMessage,
    };

    setLastQuestion(newMessage);
    const userMessage = { message: newMessage, userId: auth.id, uuid: generateUuid() };
    setMessageToSend(userMessage)

    setMainThreadHistory((prevMainThreadHistory) => [...prevMainThreadHistory, message]);

    setMainThreadHistory((prevMainThreadHistory) => [
      ...prevMainThreadHistory,
      { role: "bot", content: '', displayOptions: false }
    ]);

    setCurrentMessage("");
    setObjectFile('');
    setShowWelcome(false);
  }

  const openedModalCriteria = () => {
    if (modalType === 'image code'){
      return isChatbotModalShown && useImage === 'code'
    } else if (modalType === 'image stories'){
      return isChatbotModalShown && useImage === 'stories'
    } else {
      return isChatbotModalShown && !useImage
    }
  }

  const titleCriteria = () => {
    if (modalType === 'image code'){
      return 'ZmartBot Code Recommendations'
    } else if (modalType === 'image stories'){
      return 'ZmartBot Requirement Recommendations'
    } else {
      return 'ZmartBot General Recommendations'
    }
  }

  return (
    <div>
        <Dialog
          open={openedModalCriteria()}
          onClose={handleClose}
          fullWidth
          maxWidth="xl"
          className="chatmodal-container"
        >
          <DialogTitle className={styles.chatCustomTitle}>
            <div className="chatmodal-title">
              <img src={chatbotIcon} alt="" class="chat-logo"/>
              <h3>
                {titleCriteria()}
              </h3>
              <IconButton
                aria-label="close"
                onClick={handleClose}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M.146 0a.5.5 0 0 1 .708.708L8 7.293 15.146.146a.5.5 0 1 1 .708.708L8.707
                  8l7.147 7.146a.5.5 0 0 1-.708.708L8 8.707l-7.146
                  7.147a.5.5 0 0 1-.708-.708L7.293 8 .146.854A.5.5
                  0 0 1 .146 0z"/>
                </svg>
              </IconButton>
            </div>
          </DialogTitle>
          <div className="chatmodal-chat-content-container">
            <button onClick={toggleCollapse} className="chatmodal-collapse-button">⇦</button>
            <div className="chatmodal-conversation-window">
              { tabValue === 0 && (
                  <ChatMessageHistory
                  id="chatmodal-chatmessagehistory"
                  setMessageHistory={setMainThreadHistory}
                  messageHistory={mainThreadHistory}
                  messageTips={messageTips}
                  setMessageTips={setMessageTips}
                  setCurrentMessage={setCurrentMessage}
                  showWelcome={showWelcome}
                />
              )}
              { Object.values(childComponents).map((component) => component )}

              { tabValue !== 0 && !feedbackSent && lastQuestion && (
                <DialogContent
                  className="chatmodal-engine-feedback-dialog"
                >
                  <DialogContentText
                    className="chatmodal-engine-feedback-dialog-title"
                  >
                    {language[selectedLanguage].bot.chat.feedbackQuestion}
                </DialogContentText>
                  <div
                    className="chatmodal-engine-feedback-dialog-buttons"
                  >
                    <Button
                      onClick={addMessageToLearnedLessons}
                      disabled={state[tabValue - 1].isLoading}
                      color="primary"
                      size="large"
                      variant="contained"
                      >
                        {language[selectedLanguage].bot.chat.feedbackYes}
                      </Button>
                      <Button
                      onClick={setFeedbackSent}
                      disabled={state[tabValue - 1].isLoading}
                      color="primary"
                      size="large"
                      variant="contained"
                      >
                        {language[selectedLanguage].bot.chat.feedbackNo}
                      </Button>
                  </div>
                </DialogContent>
              )}
              { feedbackSent && (
                <DialogContent
                  className="chatmodal-engine-feedback-dialog"
                >
                  <DialogContentText
                    className="chatmodal-engine-feedback-dialog-title"
                  >
                    {language[selectedLanguage].bot.chat.feedbackThanks}
                  </DialogContentText>
                </DialogContent>
              )}
              { tabValue === 0 && (
                <DialogActions className="chatmodal-message-input">
                  {useImage ? <ImageUpload setObjectFile={setObjectFile} objectFile={objectFile}/> : <></>}
                  <TextField
                  autoFocus
                  disabled={anyIsLoading || tabValue !== 0}
                  id="name"
                  size="medium"
                  type="text"
                  variant="outlined"
                  multiline
                  placeholder={language[selectedLanguage].bot.chat.placeholder}
                  fullWidth
                  value={currentMessage}
                  onChange={(e) => setCurrentMessage(e.target.value)}
                  onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleNewUserMessage();
                  }
                  }}
                  ></TextField>
                  <Button
                    onClick={() => { handleNewUserMessage(); }}
                    disabled={!currentMessage || anyIsLoading || tabValue !== 0}
                    color="primary"
                    variant="contained"
                    size="large"
                    className={styles.chatCustomSendButton}
                    >
                    { language[selectedLanguage].bot.chat.send }
                    </Button>
                </DialogActions>
              )}
            </div>
          </div>
        </Dialog>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    user: state.user,
    auth: state.auth,
    selectedLanguage: state.user.language,
    isChatbotModalShown: state.modal.chatbot.isShown,
    defaultMessage: state.modal.chatbot.defaultMessage,
    useImage: state.modal.chatbot.image,
  };
}

const mapDispatchToProps = {
  toggleChatbotModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatModal);
