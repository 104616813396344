const API_URL = process.env.REACT_APP_BOT_MICROSERVICE_URL;

export const fetchConversations = async (userId) => {
  console.log("fetchConversations", userId);
  try {
    const response = await fetch(`${API_URL}/conversation/all`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ userId }),
    });

    if (response.ok) {
      return await response.json();
    }
    throw new Error("Error fetching conversations");
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const createChat = async (userId) => {
  try {
    const response = await fetch(`${API_URL}/conversation/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ userId }),
    });

    if (response.ok) {
      return await response.json();
    }
    throw new Error("Error creating chat");
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const sendMessage = async (conversationId, prompt, onMessageChunk) => {
  console.log("Esto se está enviando", { conversationId, prompt });

  try {
      const response = await fetch(`${API_URL}/chatbot/response`, {
          method: "POST",
          headers: {
              "Content-Type": "application/json",
          },
          body: JSON.stringify({ conversationId, prompt }),
      });

      if (response.ok) {
          const reader = response.body.getReader();
          const decoder = new TextDecoder("utf-8");
          let done = false;

          let batchId = "";

          while (!done) {
              const { done: readerDone, value } = await reader.read();
              done = readerDone;
              const text = decoder.decode(value, { stream: !done });

              text.split('\n\n').forEach((msg) => {
                console.log("test de recibido:", msg);
                  if (msg.startsWith('data: ')) {
                      const jsonString = msg.substring('data: '.length).trim();
                      if (jsonString === '[DONE]') return;

                      try {
                          const jsonData = JSON.parse(jsonString);
                          const { model, id, data } = jsonData;

                          if (model === "None" && id) {
                              batchId = id;
                              onMessageChunk({ model, batchId });
                          } else {
                              onMessageChunk({ model, data});
                          }
                      } catch (e) {
                          console.error("Error al parsear JSON:", e, "Mensaje recibido:", jsonString);
                      }
                  }
              });
          }
      } else {
          throw new Error("Error en la respuesta de la API");
      }
  } catch (error) {
      console.error("Error:", error);
      throw error;
  }
};


export const fetchMessages = async (conversationId) => {
  console.log("fetchMessages", conversationId);
  try {
    const response = await fetch(`${API_URL}/conversation/messages/withModels`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ conversationId }),
    });

    if (response.ok) {
      return await response.json();
    }
    throw new Error("Error fetching messages");
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}

export const changeChatTitle = async (conversationId, title) => {
  console.log("changeChatTitle", conversationId, title);
  try {
    const response = await fetch(`${API_URL}/conversation/title`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id: conversationId, title: title }),
    });

    if (response.ok) {
      return await response.json();
    }
    throw new Error("Error changing chat title");
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}

export const deleteChat = async (conversationId) => {
  console.log("deleteChat", conversationId);
  try {
    const response = await fetch(`${API_URL}/conversation/delete`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ conversationId }),
    });
    

    if (response.ok) {
      return await response.json();
    }
    throw new Error("Error deleting chat");
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}

export const changeTip = async (messageBatchId, chosenModel) => {
  console.log("changeTip", messageBatchId, chosenModel);
  try {
    const response = await fetch(`${API_URL}/conversation/messageBatch`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ messageBatchId, chosenModel }),
    });

    if (response.ok) {
      return await response.json();
    }
    throw new Error("Error changing tip");
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}

export const dislikeMessageBatch = async (messageBatchId) => {
  console.log("Dislike batch", messageBatchId);
  try {
    const response = await fetch(`${API_URL}/conversation/dislike`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ messageBatchId }),
    });

    if (response.ok) {
      return await response.json();
    }
    throw new Error("Error changing tip");
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}