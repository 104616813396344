// in ActionProvider.jsx
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import "./Recomendacion.css";
import { AlarmRounded } from '@material-ui/icons';

const URL = "https://lessons.zmartboard.cloud";
const host = process.env.REACT_APP_API_URL;
const website = "https://zmartboard.cloud"

const Recomendacion = ({ user, auth }) => {
  const [recomendacion, setRecomendacion] = useState({});
  const [pregunta, setPregunta] = useState("");
  const [loading, setLoading] = useState(false);
  const [respuesta, setRespuesta] = useState(false);
  const [projectId, setProjectId] = useState("");
  const [review, setReview] = useState(false);
  const [engine, setEngine] = useState("");
  // const enginesMap = {
  //   0: "recommend",
  //   1: "recommend_lev",
  //   2: "recommend_pond",
  // }

  useEffect(() => {
    loadProjectInfo();
    getActiveEngine();
  }, []);

  const handlePregunta = (e) => {
    setPregunta(e.target.value);
  }

  const handleRecommend = async (e) => {
    e.preventDefault();
    setLoading(true);
    await fetch(`${URL}/${engine}?query=${pregunta}`, {
      method: 'GET',
    }).then((response) => response.json())
      .then((data) => setRecomendacion(data.lessons[0]))
      .then(() => setRespuesta(!respuesta));
    setLoading(false);
  }

  const loadProjectInfo = () => {
    fetch(`${host}/projects/`, {
      method: 'GET',
      headers: {
        uid: auth.uid,
        client: auth.client,
        'access-token': auth.token
      }
    })
      .then((response) => response.json())
      .then((data) => setProjectId(data[0].id))
  }

  // Add to useEffect when deployed in ec2
  const getActiveEngine = async () => {
    await fetch(`${host}/active_engines`, {
      method: 'GET',
      headers: {
        uid: auth.uid,
        client: auth.client,
        'access-token': auth.token
      }
    })
      .then((response) => response.json())
      .then(data => setEngine(data.name))
  }

  const handleReview = async (opinion) => {
    // e.preventDefault();
    console.log(opinion)
    console.log(user)
    console.log(auth)
    await fetch(`${URL}/lesson_user_rating`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Host": "lessons.zmartboard.cloud",
            "Connection": "keep-alive",
            "Accept": "*/*",
            "Origin": website,
        },
        body: JSON.stringify({
            userId: auth.id,
            lessonId: recomendacion.id,
            attemps: 1,
            points: opinion,
            querytext: pregunta,
        })
    });
    setReview(!review);
  }

  return (
    <div className='react-chatbot-kit-chat-bot-message-container'></div>


  )
};

function mapStateToProps(state) {
  return {
    user: state.user,
    auth: state.auth,
  };
}


export default connect(mapStateToProps)(Recomendacion);