import React, { useEffect, useState } from "react";
import Chatbot from "react-chatbot-kit";
import { connect, useDispatch } from 'react-redux';
import ActionProvider from "./ActionProvider";
import MessageParser from "./MessageParser";
import config from "./config";
import 'react-chatbot-kit/build/main.css';
import './ChatbotService.css';
import language from '../constants/language';
import chatbotIcon from '../assets/images/isotipo-normal.png';
import chatbotLoadingIcon from '../assets/images/isotipo-loading.png';

// import { ReactComponent as ButtonIcon } from "../assets/chatbot-logo.png";

function ChatbotService(props) {
  const [showChatbot, toggleChatbot] = useState(false);
  const { selectedLanguage } = props;

  return (
    <>
      <div className="app-chatbot-container">
        {
          showChatbot && (
            <Chatbot
              config={config}
              messageParser={MessageParser}
              actionProvider={ActionProvider}
              placeholderText={language[selectedLanguage].bot.chat.placeholder}
              headerText={language[selectedLanguage].bot.chat.header}
            />
          )
        }
      </div>

      <button
        className="app-chatbot-button"
        onClick={() => toggleChatbot((prev) => !prev)}
      >
        <img src={showChatbot? chatbotLoadingIcon : chatbotIcon} alt="" className="app-chatbot-button-icon"/>
      </button>
    </>
  )

}

function mapStateToProps(state) {
  return {
    user: state.user,
    selectedLanguage: state.user.language,
  };
}

export default connect(mapStateToProps)(ChatbotService);