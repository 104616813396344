import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import { Configuration, OpenAIApi } from 'openai';

// interface UserMessage {
//     userId: string;
//     message: string;
//     uuid: string;
// }

// interface Engine {
//     id: string;
//     label: string;
//     endpointUrl: string;
// }

const BASE_URL = process.env.REACT_APP_CHAT_API_URL;

export const generateUuid = () => {
    return uuidv4();
}

export const zmartbotApi = {
    getEngines: () => {
        const path = '/engines';
        return axios.get(`${BASE_URL}${path}`);
    },
    sendMessage: (message, engine) => {
        return axios.post(
            `${BASE_URL}${engine.endpointUrl}`,
            message,
            {
                timeout: 20000000,
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        )
    },
    sendMessageImage: (message, mainThreadHistory, image, useImage) => {
        if (image){
            if (useImage === 'code') {
                message.message = `${message.message}. Además dame el código html, css y js para esta imagen.`
            } else if (useImage === 'stories') {
                message.message = `${message.message}. Además, dame las historias de usuario para esta imagen.`
            }
            return axios.post(
                `${BASE_URL}/api/chat/replicate`,
                {
                    image: image,
                    prompt: message.message
                },
                {
                    timeout: 20000000,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            )
        } else {
            const configuration = new Configuration({
                apiKey: process.env.REACT_APP_OPEN_AI_KEY,
            });
            const openai = new OpenAIApi(configuration);
            const history = mainThreadHistory.map((element) => {
                return {
                  role: element.role === 'bot' ? 'assistant' : 'user',
                  content: element.content,
                }
            })
            history.pop()
            return openai.createChatCompletion({
                model: "gpt-4",
                messages: history,
            });
        }
    }
};