import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import React, { useState, useProps, useEffect, useMemo, forwardRef } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import ReactMarkdown from "react-markdown"
import remarkGfm from "remark-gfm"
import rehypeRaw from "rehype-raw";
import language from '../../../constants/language';
import { zmartbotApi, generateUuid } from "../api/messages";
import { fetchEventSource } from "@microsoft/fetch-event-source";

import { connect } from "react-redux";
import {Prism as SyntaxHighlighter} from "react-syntax-highlighter"
import {dracula} from "react-syntax-highlighter/dist/esm/styles/prism"

import "./ChatEngineMessages.css";

const BASE_URL = process.env.REACT_APP_CHAT_API_URL;

const ChatEngineMessages = ({ engine, message, isShown, onStateChange, lastMessage, newMessageSeen, isLoading, selectedLanguage, image, setObjectFile, messageTips, setMessageTips, setMainThreadHistory, useImage, mainThreadHistory }) => {
  const [messageHistory, setMessageHistory] = useState([{ role: "bot", content: language[selectedLanguage].bot.chat.firstMessage }]);

  function setLastMessage(value) {
    onStateChange({ lastMessage: value });
  }

  function setNewMessageSeen(value) {
    onStateChange({ newMessageSeen: value });
  }

  function setIsLoading(value) {
    onStateChange({ isLoading: value });
  }


  useEffect(() => {
    if (message) {
      addNewMessage(message, image);
    }
  }, [message]);

  useEffect(() => {
    moveToEndOfMessageHistory();
  }, [messageHistory, isShown]);

  const messageLastIndex = useMemo(() => {
    return messageHistory.length - 1;
  }, [messageHistory]);

function moveToEndOfMessageHistory() {
  const messageHistoryContainer = document.getElementById("chatmodal-messages-container");
  if (!!messageHistoryContainer) {
    messageHistoryContainer.scrollTop = messageHistoryContainer.scrollHeight - messageHistoryContainer.clientHeight;
  }
}

  async function addNewMessage(userMessage, image) {
    setMessageHistory((messageHistory) => [...messageHistory, { role: 'user', content: userMessage.message}]);

    setIsLoading(true);

    let chatResponse = {};

    if (!useImage) {
      if (engine.id === 1 || engine.id === 2){
        const url = engine.id === 1 ? 
          `https://engines.ai.cparedesr.com/api/stream/openai` : `https://engines.ai.cparedesr.com/api/stream/enhanced`
        let completeMessage = '';
        const fetchData = async () => {
          await fetchEventSource(url, {
            method: "POST",
            headers: { 'Content-Type': 'application/json', Accept: "text/event-stream" },
            body: JSON.stringify({
              message: userMessage.message.toString(),
              uuid: userMessage.uuid.toString(),
              userId: userMessage.userId.toString()
            }),
            onmessage(event) {
              completeMessage += event.data ? event.data : '\n'
              chatResponse = { role: 'bot', content: completeMessage, displayOptions: true };
              if (engine.id === 1){
                setMainThreadHistory((messageHistory) => {
                  messageHistory.pop()
                  return [...messageHistory, chatResponse]
                })
                moveToEndOfMessageHistory();
              }
              messageTips.selected = 'tip1'
              messageTips[engine.name] = chatResponse.content
              setMessageTips(messageTips)
            },
            onerror(err) {
              console.log("There was an error from server", err);
              chatResponse = { role: 'bot', content: language[selectedLanguage].bot.chat.errorMessage, displayOptions: false };
              if (engine.id === 1){
                setMainThreadHistory((messageHistory) => {
                  messageHistory.pop()
                  return [...messageHistory, chatResponse]
                })
                moveToEndOfMessageHistory();
              }
              messageTips.selected = 'tip1'
              messageTips[engine.name] = chatResponse.content
              setMessageTips(messageTips)
            },
            onclose(){
              setIsLoading(false);
              setNewMessageSeen(false);
            }
          });
        };
        fetchData();
      }
      else {
        zmartbotApi.sendMessage(userMessage, engine).then((response) => {
          chatResponse = { role: 'bot', content: response.data.message, displayOptions: true };
        }).catch((error) => {
          chatResponse = { role: 'bot', content: language[selectedLanguage].bot.chat.errorMessage, displayOptions: false };
        }).finally(() => {
          setNewMessageSeen(false);
          setMessageHistory((messageHistory) => [...messageHistory, chatResponse]);
          messageTips.selected = 'tip1'
          messageTips[engine.name] = chatResponse.content
          setMessageTips(messageTips)
          setIsLoading(false);
        });
      }
    } else {
      zmartbotApi.sendMessageImage(userMessage, mainThreadHistory, image, useImage).then((response) => {
        chatResponse = { role: 'bot', 
        content: image ? response.data.message : response.data.choices[0].message.content, 
        displayOptions: false 
      };
      }).catch((error) => {
        chatResponse = { role: 'bot', content: language[selectedLanguage].bot.chat.errorMessage, displayOptions: false };
      }).finally(() => {
        setNewMessageSeen(false);
        setLastMessage(chatResponse);
        moveToEndOfMessageHistory();
        setMessageHistory((messageHistory) => [...messageHistory, chatResponse]);
        setMainThreadHistory((messageHistory) => {
          messageHistory.pop()
          return [...messageHistory, chatResponse]
        });
        setMessageTips({
          lessons: '-',
          tip1: '-',
          tip2: '-',
          tip3: '-',
          tip4: '-',
          selected: 'tip1'
        });
        setIsLoading(false);
      });
    }
  }

  function LinkRenderer(props) {
    return (
      <a href={props.href} target="_blank" rel="noreferrer">
        {props.children}
      </a>
    );
  }
  return (
    isShown && (
      <DialogContent>
          <DialogContentText
            className="chatmodal-messages-container"
            id="chatmodal-messages-container"
          >
          {messageHistory.map((message, index) => (
            <div key={index} className="chatmodal-message">
                <p className="chatmodal-message-role">{ message.role.toUpperCase() } { (index > 0 && index == messageLastIndex) ? '(NEW)' : '' }</p>
                <ReactMarkdown
                  className="chatmodal-response"
                  children={message.content}
                  rehypePlugins={[rehypeRaw]}
                  remarkPlugins={[remarkGfm]}
                  components={{
                    a: LinkRenderer,
                    code({node, inline, className, children, ...props}) {
                      const match = /language-(\w+)/.exec(className || "")
                      return !inline && match ? (
                        <SyntaxHighlighter
                          {...props}
                          children={String(children).replace(/\n$/, "")}
                          style={dracula}
                          language={match[1]}
                          PreTag="div"
                        />
                      ) : (
                        <code {...props} className={className}>
                          {children}
                        </code>
                      )
                    }
                  }}
                />
                { isLoading && index === messageHistory.length - 1 && (
                  <div className="chatmodal-message-loading">
                    <CircularProgress />
                  </div>
                )}
            </div>
          ))}
          </DialogContentText>
        </DialogContent>
    )
  );
};

function readChunks(reader) {
  return {
      async* [Symbol.asyncIterator]() {
          let readResult = await reader.read();
          while (!readResult.done) {
              yield readResult.value;
              readResult = await reader.read();
          }
      },
  };
}


function mapStateToProps(state) {
  return {
    user: state.user,
    selectedLanguage: state.user.language,
  };
}

export default connect(mapStateToProps)(ChatEngineMessages);