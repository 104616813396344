import React from "react";
import chatbotIcon from '../../../assets/images/isotipo-normal.png';
import "../styles/ChatWelcomeView.css";

import HelpIcon from '../../../assets/help_outline_icon.svg';
import InfoIcon from '../../../assets/info_outline_icon.svg';
import TipsAndUpdatesIcon from '../../../assets/lightbulb_outline_icon.svg';

function WelcomeView({ setCurrentMessage }) {
  console.log("Recarga WelcomeView");
  return (
    <div className="chatmodal-nochat-selected-container">
      <img src={chatbotIcon} alt="logo chat" className="chatmodal-main-logo" />
      <div className="chatmodal-starting-options">
        <button
          className="chatmodal-option-button"
          onClick={() => setCurrentMessage("Quiero informacion sobre scrum")}
        >
          <img src={InfoIcon} alt="icono de informacion" className="chatmodal-option-icon" />
          <span>Información sobre Scrum</span>
        </button>

        <button
          className="chatmodal-option-button"
          onClick={() => setCurrentMessage("Quiero preguntar sobre frameworks comunes para el desarrollo web")}
        >
          <img src={HelpIcon} alt="icono de ayuda" className="chatmodal-option-icon" />
          <span>Preguntar por frameworks</span>
        </button>
        
        <button
          className="chatmodal-option-button"
          onClick={() => setCurrentMessage("Quiero algunos consejos para programar eficientemente")}
        >
          <img src={TipsAndUpdatesIcon} alt="icono de consejos" className="chatmodal-option-icon" />
          <span>Consejos para programar</span>
        </button>
      </div>
    </div>
  );
}

export default WelcomeView;
