import React from "react";

import "./RecomendacionButtons.css";
import { connect } from "react-redux";
import language from '../../../constants/language';

const RecomendacionButtons = (props) => {
  const { selectedLanguage } = props;
  const options = [
    { text: 'Dame una recomendación', handler: props.actionProvider.handleRecomendacion, id: 1 },
    { text: 'Dame una recomendación visual', handler: props.actionProvider.handleRecomendacionOptions, id: 2 },
  ];

  const optionsMarkup = options.map((option) => (
    <button
      className="learning-option-button"
      key={option.id}
      onClick={option.handler}
    >
      {option.text}
    </button>
  ));

  return <div className="learning-options-container">{optionsMarkup}</div>;
};

function mapStateToProps(state) {
  return {
    selectedLanguage: state.user.language,
  };
}

export default connect(mapStateToProps)(RecomendacionButtons);
