import React from 'react';

const MessageParser = ({ children, actions }) => {
  const parse = (message) => {
    if (message.toLowerCase().includes('entregas')) {
      actions.handleEntregas();
    }
    else if (message.toLowerCase().includes('equipo') || message.toLowerCase().includes('docente')) {
      actions.handleEquipoDocente();
    }
    else if (message.toLowerCase().includes('plantilla') || message.toLowerCase().includes('plantillas') || message.toLowerCase().includes('plantilla de entregas') || message.toLowerCase().includes('documentos')) {
      actions.handlePlantillas();
    } 
    else if (message.toLowerCase().includes('menu') || message.toLowerCase().includes('opciones') || message.toLowerCase().includes('opción')) {
      actions.handleMenu();
    }
    else if (message.toLowerCase().includes('quiero') || message.toLowerCase().includes('recomendacion') || message.toLowerCase().includes('recomendación') || message.toLowerCase().includes('recomendaciones') || message.toLowerCase().includes('ayuda') || message.toLowerCase().includes('orientación') || message.toLowerCase().includes('orientacion')) {
      actions.handleRecomendacionButtons();
    } 
    else {
      actions.handleRecomendacionButtons("Mis acciones estan limitadas a las opciones mostradas, ¿En qué puedo ayudarte?")
    }
  };

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          parse: parse,
          actions,
        });
      })}
    </div>
  );
};

export default MessageParser;