import React, { useState } from "react";
import ChatModal from "../../../components/chatModal/ChatModal";

const ModalWidget = () => {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <>
      {isModalOpen && <ChatModal onClose={closeModal} />}
    </>
  );
};

export default ModalWidget;
